import axios from "axios";
import { SERVER_URL } from "../utils";

export const uploadFile = async (data: any) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/image/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return await axios.request(config);
};
export const loginAdmin = async (data: any) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/admin/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  return await axios.request(config);
};
export const getAllCategories = async () => {
  let config = {
    method: "get",
    url: SERVER_URL + `/category`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const getCategories = async (currentPage: number, limit: number) => {
  let config = {
    method: "get",
    url: SERVER_URL + `/category/pagination?page=${currentPage}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const createCategory = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/category/add",
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  return await axios.request(config);
};
export const updateCategory = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/category/update/" + data.id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};
export const deleteCategory = async (id: string, token: string) => {
  let config = {
    method: "delete",
    url: SERVER_URL + "/category/delete/" + id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const getAllChannels = async () => {
  let config = {
    method: "get",
    url: SERVER_URL + `/channel`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const getChannels = async (currentPage: number, limit: number) => {
  let config = {
    method: "get",
    url: SERVER_URL + `/channel/pagination?page=${currentPage}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const createChnnel = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/channel/add",
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  return await axios.request(config);
};
export const updateChannel = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/channel/update/" + data.id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};
export const deleteChannel = async (id: string, token: string) => {
  let config = {
    method: "delete",
    url: SERVER_URL + "/channel/delete/" + id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const getAllNews = async () => {
  let config = {
    method: "get",
    url: SERVER_URL + `/news/all`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const getNews = async (currentPage: number, limit: number) => {
  let config = {
    method: "get",
    url: SERVER_URL + `/news?page=${currentPage}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const createNews = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/news/add",
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  return await axios.request(config);
};
export const updateNews = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/news/update/" + data.id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};
export const deleteNews = async (id: string, token: string) => {
  let config = {
    method: "delete",
    url: SERVER_URL + "/news/delete/" + id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const getUsers = async (currentPage: number, limit: number) => {
  let config = {
    method: "get",
    url: SERVER_URL + `/user?page=${currentPage}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};

export const updateUserStatus = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/user/update/status/" + data.id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};
export const deleteUser = async (id: string, token: string) => {
  let config = {
    method: "delete",
    url: SERVER_URL + "/user/delete/" + id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};

export const getFaqs = async (currentPage: number, limit: number) => {
  let config = {
    method: "get",
    url: SERVER_URL + `/faq/pagination?page=${currentPage}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const addFaq = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/faq/add",
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  return await axios.request(config);
};
export const updateFaq = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/faq/update/" + data.id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};
export const deleteFaq = async (id: string, token: string) => {
  let config = {
    method: "delete",
    url: SERVER_URL + "/faq/delete/" + id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const getLinks = async (currentPage: number, limit: number) => {
  let config = {
    method: "get",
    url: SERVER_URL + `/youtube/pagination?page=${currentPage}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const addLink = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/youtube/add",
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  return await axios.request(config);
};
export const updateLink = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/youtube/update/" + data.docId,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};
export const deleteLink = async (id: string, token: string) => {
  let config = {
    method: "delete",
    url: SERVER_URL + "/youtube/delete/" + id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};


export const getSigmas = async (currentPage: number, limit: number) => {
  let config = {
    method: "get",
    url: SERVER_URL + `/sigma/pagination?page=${currentPage}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};
export const updateSigmaTemplate = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/sigma/update/template/" + data.id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};
export const updateSigma = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/sigma/update/" + data.id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};
export const deleteSigma = async (id: string, token: string) => {
  let config = {
    method: "delete",
    url: SERVER_URL + "/sigma/delete/" + id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};

export const updateSigmaStatus = async (data: any, token: string) => {
  let config = {
    method: "post",
    url: SERVER_URL + "/sigma/update/status/" + data.id,
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  return await axios.request(config);
};

export const getTemplates = async () => {
  let config = {
    method: "get",
    url: SERVER_URL + `/template`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(config);
};

export const getSigmaComments = async (id: string) => {
  let config = {
    method: "get",
    url: SERVER_URL + "/sigma-comment/" + id,
    headers: {
      "Content-Type": "application/json",
    }
  };

  return await axios.request(config);
};
export const deleteComment = async (id: string, token: string) => {
  let config = {
    method: "delete",
    url: SERVER_URL + "/sigma-comment/delete/" + id,
    headers: {
      "x-auth-token": token
    },
  };

  return await axios.request(config);
};