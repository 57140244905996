import { useEffect, useMemo, useState } from "react";
import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllNews, getSigmaComments, getTemplates, updateSigma, updateSigmaTemplate } from "../APIS";
import { useSelector } from "react-redux";
import { CommentType, SigmaModel, State } from "../types";
import Comments from "../components/Comments";

export const Sigma = () => {
	const { state } = useLocation();
	const token = useSelector((state: State) => state.admin.token);
	const [templates, setTemplates] = useState([]);
	const [loadTemplates, setLoadTemplates] = useState(false);
	const [templateModal, setTemplateModal] = useState(false);
	const [templateData, setTemplateData] = useState<any>({
		isVideoTemplate: false,
		templateUrl: null,
		templateID: null
	});
	const [news, setNews] = useState([]);
	const [loadNews, setLoadNews] = useState(false);
	const [newsModal, setNewsModal] = useState(false);
	const [newsId, setNewsId] = useState('');
	const [sigma, setSigma] = useState(null);
	const [comments, setComments] = useState<CommentType[]>([]);

	useEffect(() => {
		if (state) {
			getSigmaComments(state._id).then(res => {
				setComments(res.data.data);
			})
				.catch(err => {
					console.error("Error while fetching comments: " + err);

				})
		}
	}, [state]);


	const newsClickHandler = async (id: string) => {
		setLoadNews(true);
		try {
			const res = await getAllNews();
			setNews(res.data.data);
			setNewsId(id);
			setNewsModal(true);
			setLoadNews(false);
		} catch (error: any) {
			setLoadNews(false);
			toast.error(error?.message);
		}
	}
	const updateNewsHandler = async (id: string) => {
		setLoadNews(true);
		try {
			const res = await updateSigma({ id, news: newsId }, token);
			setSigma(res.data.data);
			setLoadNews(false);
			setNewsModal(false);
			toast.success("Updated");
		} catch (error: any) {
			setLoadNews(false);
			toast.error(error?.message);
		}
	}
	const templateSelectHandler = async (id: string) => {
		const ti = templates.findIndex((it: any) => it._id === id);
		if (ti > -1) {
			const tem: any = templates[ti];
			setTemplateData({
				templateID: tem._id,
				isVideoTemplate: tem.isVideoTemplate,
				templateUrl: tem.url,
			})
		}
	}
	const updateTemplateHandler = async (id: string) => {
		setLoadTemplates(true);
		try {
			const res = await updateSigmaTemplate({ ...templateData, id }, token);
			console.log(res);

			setSigma(res.data.data);
			setLoadTemplates(false);
			setTemplateModal(false);
			toast.success("Updated");
		} catch (error: any) {
			setLoadTemplates(false);
			toast.error(error?.message);
		}
	}
	const templateClickHandler = async (sigma: SigmaModel) => {
		setLoadTemplates(true);
		try {
			const res = await getTemplates();
			setTemplates(res.data.data);
			setTemplateData({
				isVideoTemplate: sigma.isVideoTemplate,
				templateID: sigma.templateID,
				templateUrl: sigma.templateUrl,
			})
			setLoadTemplates(false);
			setTemplateModal(true);
		} catch (error: any) {
			setLoadTemplates(false);
			toast.error(error?.message);
		}
	}

	const item = useMemo<SigmaModel>(() => {
		const result = sigma ? sigma : state;
		return result;
	}, [sigma, state]);

	console.log(item);


	return (
		<Container>
			<div className="d-flex gap-3">
				<Link to="/sigmas" style={{ fontSize: "25px" }}>
					<IoMdArrowRoundBack />
				</Link>
				<h2>Sigma</h2>
			</div>
			{
				item?.isVideoSigma ? (
					<video width="100%" height="400" controls>
						<source src={item?.url} />
					</video>
				) : (
					<img
						width="100%"
						height={400}
						src={
							item?.url
								? item?.url
								: "/placeholder.png"
						}
						alt={item?.title}
					/>
				)
			}
			<div className="card mb-4 p-2">
				<h3>News</h3>
				<label>Title</label>
				<h5>{item?.news?.title}</h5>

				<img
					width="100%"
					style={{ maxWidth: "400px" }}
					height={300}
					src={
						item?.news?.fileUrl
							? item?.news?.fileUrl
							: "/placeholder.png"
					}
					alt={item?.title}
				/>
				<h5>{item?.news?.shortDescription}</h5>

				<div className="d-flex gap-4">
					<Button onClick={() => newsClickHandler(item.news?._id)} variant="secondary" className=" mt-3 px-5">
						{loadNews ? <Spinner variant="secondary" animation="grow" /> : "Update News"}
					</Button>
				</div>
			</div>
			<div className="card mb-4 p-2">
				<h3>Template</h3>
				{
					item?.isVideoTemplate ? (
						<video width="100%" height="400" controls src={item?.templateUrl}>
						</video>
					) : (
						<img
							width="100%"
							height={400}
							src={
								item?.templateUrl
									? item?.templateUrl
									: "/placeholder.png"
							}
							alt={item?.title}
						/>
					)
				}

				<div className="d-flex gap-4">
					<Button onClick={() => templateClickHandler(item)} variant="secondary" className=" mt-3 px-5">
						{loadTemplates ? <Spinner variant="secondary" animation="grow" /> : "Update Template"}
					</Button>
				</div>
			</div>
			<div className="card mb-4 p-2">
				<h3>Reviews</h3>
				<Comments comments={comments} setComments={setComments}/>
			</div>
			<Modal show={templateModal} size="lg">
				<Modal.Header>Templates
					<button onClick={() => setTemplateModal(false)} className="btn-close"></button>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className="mb-3">
						<Form.Label style={{ fontWeight: "bold" }} htmlFor='template'>Template</Form.Label>
						<Form.Select value={templateData.templateID} onChange={e => templateSelectHandler(e.target.value)}>
							<option value="">Search Template</option>
							{templates.map((e: any, i: number) => (
								<option key={i} value={e._id}>
									{e.title}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Button onClick={() => updateTemplateHandler(item._id)} className="btn primary-button mt-3 px-5">
						{loadTemplates ? <Spinner animation="grow" /> : "Update"}
					</Button>
				</Modal.Body>
			</Modal>
			<Modal show={newsModal} size="lg">
				<Modal.Header>News
					<button onClick={() => setNewsModal(false)} className="btn-close"></button>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className="mb-3">
						<Form.Label style={{ fontWeight: "bold" }} htmlFor='news'>News</Form.Label>
						<Form.Select value={newsId} onChange={e => setNewsId(e.target.value)}>
							<option value="">Search news</option>
							{news.map((e: any, i: number) => (
								<option key={i} value={e._id}>
									{e.title}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Button onClick={() => updateNewsHandler(item._id)} className="btn primary-button mt-3 px-5">
						{loadNews ? <Spinner animation="grow" /> : "Update"}
					</Button>
				</Modal.Body>
			</Modal>
		</Container>
	);
};
