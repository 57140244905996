import { Container, Modal, Table } from "react-bootstrap";
import useSWR from "swr";
import { Input, Loader, Select, Textarea } from "..";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  deleteNews,
  getAllCategories,
  getAllChannels,
  getNews,
  updateNews
} from "../../APIS";
import { useSelector } from "react-redux";
import { State } from "../../types";
import { FcNext, FcPrevious } from "react-icons/fc";
import { checkAuthError } from "../../utils";
import { Link, useNavigate } from "react-router-dom";

const LIMIT = 10;
export const News = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState({
    isLoaded: false,
    data: [],
  });
  const [channels, setChannels] = useState({
    isLoaded: false,
    data: [],
  });
  const [state, setState] = useState({
    id: "",
    title: "",
    frontTitle: "",
    category: "",
    channel: "",
    description: "",
    fileUrl: "",
  });
  const { data, mutate, error } = useSWR("/getNews", () =>
    getNews(currentPage, LIMIT)
  );
  const token = useSelector((state: State) => state.admin.token);

  useEffect(() => {
    getAllCategories().then(res => {
      setCategories({
        isLoaded: true,
        data: res.data.data
      })
    })
      .catch(Err => {
        console.log("Fetching category: ", Err);

      })
  }, []);
  useEffect(() => {
    getAllChannels().then(res => {
      setChannels({
        isLoaded: true,
        data: res.data.data
      })
    })
      .catch(err => {
        console.log("Fetching chanels: ", err);
      })
  }, []);

  const validations = yup.object().shape({
    title: yup.string().required(),
    category: yup.string().required(),
    channel: yup.string().required(),
    frontTitle: yup.string().required(),
    description: yup.string().required()
  });

  const clearForm = () => {
    setState({
      id: "",
      title: "",
      category: "",
      frontTitle: "",
      channel: "",
      fileUrl: "",
      description: ""
    });
  };

  const changeHandler = async (key: string, value: string) => {
    setState((p: any) => ({
      ...p,
      [key]: value,
    }));
  };


  const deleteHandler = async (item: any) => {
    const c = window.confirm(`Are you sure you want to delete this News?`);
    if (!c) return;
    try {
      await deleteNews(item._id, token);
      mutate();
      toast.success("Deleted successfully");
    } catch (error: any) {
      checkAuthError(error);
      toast.error(error.message);
    }
  };
  const handleSubmit = async (values: any) => {
    try {
      if (state.id.length) {
        await updateNews({ ...values, id: state.id, fileUrl: state.fileUrl }, token);
      }
      mutate();
      setShow(false);
      clearForm();
    } catch (error: any) {
      checkAuthError(error);
      toast.error(error.message);
    }
  };

  if (error) {
    alert(error.message);
    return <></>;
  }
  if (!data) return <Loader />;
  return (
    <Container>
      <Link to={"/news/form"} className="btn btn-outline-primary mb-3">Add News</Link>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>File</th>
            <th>Heading</th>
            <th>Rest of the News</th>
            <th>Summarised News</th>
            <th>Full News</th>
            <th>Category</th>
            <th>Channel</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.data.data.map((item: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <img
                  width={50}
                  height={50}
                  style={{ borderRadius: "50%" }}
                  src={
                    item.fileUrl
                      ? item.fileUrl
                      : "placeholder.png"
                  }
                  alt={item.name}
                />
              </td>
              <td>{item.title}</td>
              <td>{item.frontTitle}</td>
              <td>{item.shortDescription.slice(0, 50)}</td>
              <td>{item.description.length > 50 ? item.description.slice(0, 50) + "..." : item.description}</td>
              <td>{item.category?.name}</td>
              <td>{item.channel?.name}</td>
              <td>
                <Button
                  className="btn primary-button"
                  onClick={() => navigate('/news/form', { state: item })}
                >
                  View/Edit
                </Button>
              </td>
              <td>
                <Button
                  className="btn btn-danger"
                  onClick={() => deleteHandler(item)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Container
        className="pagination-container"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          maxWidth: "150px",
          margin: 0,
        }}
      >
        <Button
          className="btn primary-button"
          disabled={currentPage === 1}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
          onClick={() => {
            setCurrentPage((p) => p - 1);
            setTimeout(() => {
              mutate();
            }, 100);
          }}
        >
          <FcPrevious />
          <>Previous</>
        </Button>
        <Container
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <span> {currentPage}</span> <span>/</span>
          <span> {data.data.totalPages}</span>
        </Container>
        <Button
          className="btn primary-button px-2"
          onClick={() => {
            setCurrentPage((p) => p + 1);
            setTimeout(() => {
              mutate();
            }, 100);
          }}
          disabled={data.data.totalPages <= currentPage}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <>Next</>
          <FcNext />
        </Button>
      </Container>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header>News Form</Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={state}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Container className="mb-3">
                  <img
                    width={"100%"}
                    height={300}
                    style={{ borderRadius: "5px" }}
                    src={state.fileUrl ? state.fileUrl : "placeholder.png"}
                    alt={state.title}
                  />
                </Container>

                <Input
                  name="title"
                  label="Heading"
                  value={state.title}
                  changeHandler={changeHandler}
                />
                <Input
                  name="frontTitle"
                  label="Front Heading"
                  value={state.frontTitle}
                  changeHandler={changeHandler}
                />
                <Select
                  name="category"
                  label="Category"
                  data={categories.data}
                  searchKey="_id"
                  disabled={true}
                  searchValue="name"
                  value={state.category}
                  changeHandler={changeHandler}
                />
                <Select
                  name="channel"
                  label="Channel"
                  data={channels.data}
                  searchKey="_id"
                  disabled={true}
                  searchValue="name"
                  value={state.channel}
                  changeHandler={changeHandler}
                />
                <Textarea
                  name="description"
                  label="Description"
                  value={state.description}
                  changeHandler={changeHandler}
                />
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
