import { Col, Container, Row } from "react-bootstrap";
import { Card, SellerIcon } from "../components";
import { HiOutlineUsers } from "react-icons/hi";
import { GiNewspaper } from "react-icons/gi";
// import { getDashboardData } from "../APIS";


export const Dashboard = () => {
  // const { data, error } = useSWR("/getDashboardData", getDashboardData);
  // if (error) {
  //   toast.error(error.message);
  //   return <></>;
  // }

  // if (!data) return <Loader />;
  return (
    <Container>
      <h3 className="mb-3">Dashboard</h3>
      <Row>
        <Col>
          <Card
            title="Users"
            value={0}
            Icon={HiOutlineUsers}
            bgcolor="bg-green"
            txtcolor="text-green"
            borderColor="border-green"
          />
        </Col>
        <Col>
          <Card
            title="News"
            value={0}
            Icon={GiNewspaper}
            bgcolor="bg-blue"
            txtcolor="text-blue"
            borderColor="border-blue"
          />
        </Col>
      </Row>
    </Container>
  );
};
