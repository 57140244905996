import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  Categories,
  Channels,
  Dashboard,
  Faqs,
  Login,
  NewsPage,
  Sigma,
  Sigmas,
  Users,
  YoutubeLinks
} from "../pages";
import { useSelector } from "react-redux";
import { State } from "../types";
import { ProtectedRoute, Sidebar } from "../components";
import { NewsForm } from "../components/News";
 
const Index = () => {
  const admin = useSelector((state: State) => state.admin);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <Sidebar>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Dashboard />}
              />
            }
          />
          <Route
            path="/categories"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Categories />}
              />
            }
          />
          <Route
            path="/channels"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Channels />}
              />
            }
          />
          
          <Route
            path="/news"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<NewsPage />}
              />
            }
          />
          <Route
            path="/news/form"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<NewsForm />}
              />
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Users />}
              />
            }
          />
          <Route
            path="/faqs"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Faqs />}
              />
            }
          />
          <Route
            path="/sigmas"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Sigmas />}
              />
            }
          />
          <Route
            path="/sigmas/sigma"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Sigma />}
              />
            }
          />
          <Route
            path="/youtubelinks"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<YoutubeLinks />}
              />
            }
          />
        </Routes>
      </Sidebar>
    </Router>
  );
};

export default Index;
