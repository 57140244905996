import {
  Container,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import useSWR from "swr";
import { Input, Loader } from "../components";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory
} from "../APIS";
import { useSelector } from "react-redux";
import { FcPrevious, FcNext } from "react-icons/fc";
import { State } from "../types";
import { checkAuthError } from "../utils";

const LIMIT = 10;
export const Categories = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [fileUpload, setFileUpload] = useState(false);
  const [show, setShow] = useState(false);
  const [state, setState] = useState<any>({
    id: "",
    name: "",
    color: "",
    sortIndex: 1
  });

  const { data, mutate, error } = useSWR("/getCategories", () =>
    getCategories(currentPage, LIMIT)
  );
  const token = useSelector((state: State) => state.admin.token);

  const validations = yup.object().shape({
    name: yup.string().required(),
    color: yup.string().required(),
    sortIndex: yup.number()
      .min(1, "Must be more than 1 characters")
      .required("This field is requried")
  });

  const clearForm = () => {
    setState({
      id: "",
      name: "",
      color: "",
      sortIndex: 1
    });
  };

  const changeHandler = async (key: string, value: string) => {
    setState((p: any) => ({
      ...p,
      [key]: value,
    }));
  };
  const deleteHandler = async (id: string) => {
    const c = window.confirm("Are you sure to delete?");
    if (!c) return;
    try {
      setLoading(true);
      await deleteCategory(id, token);
      setLoading(false);
      mutate();
      setShow(false);
    } catch (error: any) {
      checkAuthError(error);
      toast.error(error.message);
    }
  };
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      if (state.id.length) {
        await updateCategory(
          {
            id: state.id,
            ...values
          },
          token
        );
      } else {
        await createCategory(
          {
            ...values,
          },
          token
        );
      }
      setLoading(false);
      mutate();
      setShow(false);
      clearForm();
    } catch (error: any) {
      setLoading(false);
      checkAuthError(error);
      toast.error(error.message);
    }
  };

  const editHandler = async (item: any) => {
    setShow(true);
    setState({
      id: item._id,
      name: item.name,
      color: item.color,
      sortIndex: item.sortIndex,
    });
  };
  const addHandler = async () => {
    clearForm();
    setShow(true);
  };


  if (error) {
    alert(error.message);
    return <></>;
  }
  if (!data) return <Loader />;
  return (
    <Container>
      <Container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Categories</h4>
        <Button className="btn primary-button" onClick={addHandler}>
          Add
        </Button>
      </Container>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Color</th>
            <th>Sort Index</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.data.data.map((item: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.color}</td>
              <td>{item.sortIndex}</td>
              <td>
                <Button
                  className="btn primary-button"
                  onClick={() => editHandler(item)}
                >
                  Edit
                </Button>
              </td>
              <td>
                <Button
                  className="btn btn-danger"
                  onClick={() => deleteHandler(item._id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Container
        className="pagination-container"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          maxWidth: "150px",
          margin: 0,
        }}
      >
        <Button
          className="btn primary-button"
          disabled={currentPage === 1}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
          onClick={() => {
            setCurrentPage((p) => p - 1);
            setTimeout(() => {
              mutate();
            }, 100);
          }}
        >
          <FcPrevious />

          <>Previous</>
        </Button>
        <Container
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <span> {currentPage}</span> <span>/</span>
          <span> {data.data.totalPages}</span>
        </Container>
        <Button
          className="btn primary-button px-2"
          onClick={() => {
            setCurrentPage((p) => p + 1);
            setTimeout(() => {
              mutate();
            }, 100);
          }}
          disabled={data.data.totalPages <= currentPage}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <>Next</>
          <FcNext />
        </Button>
      </Container>
      <Modal show={show} size="lg">
        <Modal.Header>Category Form
          <button onClick={() => setShow(false)} className="btn-close"></button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={state}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className="category-form">
                <Input
                  name="name"
                  label="Name"
                  value={state.name}
                  changeHandler={changeHandler}
                />
                <Input
                  name="color"
                  label="Color"
                  value={state.color}
                  changeHandler={changeHandler}
                />
                <Input
                  name="sortIndex"
                  type="number"
                  label="Sort Index"
                  value={state.sortIndex}
                  changeHandler={changeHandler}
                />
                <Button type="submit" className="btn primary-button mt-3 px-5">
                  {loading ? <Spinner animation="grow" /> : "Submit"}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
