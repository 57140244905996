import React from "react";
import { Card, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { CommentType, ReplyType, State } from "../../types";
import { toast } from "react-toastify";
import { deleteComment } from "../../APIS";
import { useSelector } from "react-redux";


const Reply: React.FC<{ reply: ReplyType }> = ({ reply }) => {
	return (
		<ListGroupItem className="ml-4">
			<Card>
				<Card.Body>
					<Card.Text>{reply.comment}</Card.Text>
					<div className="d-flex justify-content-between">
						<div>
							<Button variant="outline-primary" size="sm">Like ({reply.likes.length})</Button>
							<Button variant="outline-danger" size="sm" className="ml-2">Dislike ({reply.disLikes.length})</Button>
						</div>
						<div>
							<p>By User: <strong>{reply.userId?.fullName}</strong></p>
						</div>
					</div>
				</Card.Body>
			</Card>
		</ListGroupItem>
	);
};

const Comment: React.FC<{ comment: CommentType, comments: CommentType[], setComments: any }> = ({ comment, comments, setComments }) => {

	const token = useSelector((st: State) => st.admin.token);

	const deleteHandler = async () => {
		try {
			await deleteComment(comment._id, token);
			setComments(comments.filter((it: CommentType) => it._id !== comment._id));
			toast.success("Deleted comment");
		} catch (error: any) {
			toast.error(error?.message);
		}
	}

	return (
		<Card className="mb-3">
			<Card.Body>
				<Card.Text>{comment.comment}</Card.Text>

				<div className="d-flex justify-content-between">
					<div>
						<Button variant="outline-primary" size="sm">Like ({comment.likes.length})</Button>
						<Button variant="outline-danger" size="sm" className="ml-2">Dislike ({comment.disLikes.length})</Button>
					</div>
					<div>
						<p>By User: <strong>{comment.userId?.fullName}</strong></p>
						<Button onClick={deleteHandler} variant="danger" size="sm" className="ml-auto">Delete</Button>
					</div>
				</div>
				{comment.replies.length > 0 && (
					<ListGroup className="mt-3">
						{comment.replies.map((reply) => (
							<Reply key={reply.id} reply={reply} />
						))}
					</ListGroup>
				)}
			</Card.Body>
		</Card>
	);
};

const Comments: React.FC<{ comments: CommentType[], setComments: any }> = ({ comments, setComments }) => {
	return (
		<div>
			{comments.length ? comments.map((comment) => (
				<Comment key={comment._id} comment={comment} setComments={setComments} comments={comments} />
			)) : (<p>There is no comments for this Post</p>)}
		</div>
	);
};

export default Comments;
