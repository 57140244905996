import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Loader } from "../Loader";
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Spinner,
} from "react-bootstrap";
import { Input } from "../Input";
import { Select } from "../Select";
import { Textarea } from "../Textarea";
import * as yup from "yup";
import {
  getAllCategories,
  getAllChannels,
  createNews,
  uploadFile,
  updateNews,
} from "../../APIS";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { State } from "../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAuthError } from "../../utils";

export const NewsForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [fileUpload, setFileUpload] = React.useState(false);
  const [frontTitleError, setFrontTitleError] = React.useState("");
  const [headingError, setHeadingError] = React.useState("");
  const [shortStoryError, setShortStoryError] = React.useState("");
  const [categories, setCategories] = React.useState({
    isLoaded: false,
    data: [],
  });
  const [channels, setChannels] = useState({
    isLoaded: false,
    data: [],
  });
  const { state: locationState } = useLocation();
  const [state, setState] = useState({
    id: locationState ? locationState._id : "",
    title: locationState ? locationState.title : "",
    frontTitle: locationState ? locationState.frontTitle : "",
    category: locationState ? locationState.category?._id : "",
    channel: locationState ? locationState.channel?._id : "",
    description: locationState ? locationState.description : "",
    fileUrl: locationState ? locationState.fileUrl : "",
    shortDescription: locationState ? locationState.shortDescription : "",
    url: locationState ? locationState.url : "",
    isStudyMode: locationState ? locationState.isStudyMode : false,
  });
  const token = useSelector((state: State) => state.admin.token);
  const navigate = useNavigate();

  const validations = yup.object().shape({
    title: yup.string().required(),
    category: yup.string().required(),
    shortDescription: yup.string().required(),
    url: yup.string().required(),
    frontTitle: yup.string().required(),
    channel: yup.string().required(),
    description: yup.string().required()
  });

  const loadCategories = React.useCallback(async () => {
    try {
      if (!categories.isLoaded) {
        const res = await getAllCategories();
        setCategories({
          isLoaded: true,
          data: res.data.data,
        });
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }, [categories.isLoaded]);

  React.useEffect(() => {
    loadCategories();
    getAllChannels().then(res => {
      setChannels({
        isLoaded: true,
        data: res.data.data
      })
    })
      .catch(err => {
        console.log("Fetching chanels: ", err);
      })
  }, [loadCategories]);

  const clearForm = () => {
    setState({
      id: "",
      title: "",
      category: "",
      channel: "",
      fileUrl: "",
      description: "",
      frontTitle: "",
      url: "",
      shortDescription: "",
      isStudyMode: false,
    });
  };

  const changeHandler = async (key: string, value: string) => {

    // if (key.includes("title") && value.length) {
    //   const check = value.split(" ");
    //   if (check.length > 20) {
    //     setHeadingError("Heading should be 1-20 words");
    //   } else {
    //     setHeadingError("");
    //   }
    // }
    // if (key.includes("frontTitle") && value.length) {
    //   const check = value.split(" ");
    //   if (check.length > 20) {
    //     setFrontTitleError("Front heading should be 1-20 words");
    //   } else {
    //     setFrontTitleError("");
    //   }
    // }
    // if (key.includes("shortDescription") && value.length) {
    //   const check = value.split(" ");
    //   if (check.length > 60) {
    //     setShortStoryError("Short story should be 1-60 words");
    //   } else {
    //     setShortStoryError("");
    //   }
    // }
    setState((p: any) => ({
      ...p,
      [key]: value,
    }));
  };

  const handleSubmit = async (values: any) => {
    try {
      if (!state.fileUrl.length) {
        toast.error("file is required!");
        return;
      }
      // const headingCheck = state.title.split(" ");
      // if (headingCheck.length > 20) {
      //   return setHeadingError("Heading should be 1-20 words");
      // }
      // const frontHeadingCheck = state.frontTitle.split(" ");
      // if (frontHeadingCheck.length > 20) {
      //   return setFrontTitleError("Front heading should be 1-20 words");
      // }
      // const shortDescriptionCheck = state.shortDescription.split(" ");
      // if (shortDescriptionCheck.length > 60) {
      //   return setShortStoryError("Short story should be 1-60 words");
      // }
      setLoading(true);
      if (state.id.length) {
        await updateNews(
          {
            ...values,
            id: state.id,
            fileUrl: state.fileUrl,
            isStudyMode: state.isStudyMode,
          },
          token
        );
        toast.success("Updated successfully!");
      } else {
        await createNews(
          {
            ...values,
            fileUrl: state.fileUrl,
            isStudyMode: state.isStudyMode,
          },
          token
        );
        toast.success("Created successfully!");
      }
      setLoading(false);
      navigate("/news");
      clearForm();
    } catch (error: any) {
      checkAuthError(error);
      toast.error(error.message);
    }
  };
  const fileUploadHandler = async (e: any) => {
    try {
      setFileUpload(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const res = await uploadFile(formData);
      const url: any = res.data.data;
      setState((p: any) => ({
        ...p,
        fileUrl: url
      }));

      setFileUpload(false);
    } catch (error: any) {
      checkAuthError(error);
      toast.error(error.message);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <h3 style={{ fontWeight: "bold" }} className="my-3">
        {locationState ? "Edit News" : "Add News"}
      </h3>
      <Formik
        initialValues={state}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <>
              <Container
                className="mb-3"
              >
                <img
                  width={"100%"}
                  height={300}
                  style={{ borderRadius: "5px" }}
                  src={state.fileUrl.length ? state.fileUrl : "placeholder.png"}
                  alt={state.title}
                />
              </Container>
              {fileUpload ? (
                <Loader />
              ) : (
                <Container className="mb-3">
                  <FormLabel style={{ fontWeight: "bold" }}>Upload Image</FormLabel>
                  <FormControl accept="image/*" type="file" onChange={fileUploadHandler} />
                </Container>
              )}
              <Input
                name="title"
                label="Heading in at most 20 words"
                value={state.title}
                changeHandler={changeHandler}
              />
              {headingError && <p className="error-message fs-12">{headingError}</p>}
              <Input
                name="frontTitle"
                label="Rest of the news in at most 20 words"
                value={state.frontTitle}
                changeHandler={changeHandler}
              />
              {frontTitleError && <p className="error-message fs-12">{frontTitleError}</p>}

              <Textarea
                name="shortDescription"
                label="Summarised News in at most 60 words"
                value={state.shortDescription}
                changeHandler={changeHandler}
              />
              {shortStoryError && <p className="error-message fs-12">{shortStoryError}</p>}
              <Textarea
                name="description"
                label="Summarised News in at most 250 words"
                value={state.description}
                changeHandler={changeHandler}
              />

              <Select
                name="category"
                label="Category"
                data={categories.data}
                searchKey="_id"
                searchValue="name"
                value={state.category}
                changeHandler={changeHandler}
              />
              <Input
                name="url"
                label="URL"
                value={state.url}
                changeHandler={changeHandler}
              />
              <Select
                name="channel"
                label="Channel"
                data={channels.data}
                searchKey="_id"
                searchValue="name"
                value={state.channel}
                changeHandler={changeHandler}
              />

              <div className="container">
                <div className="custom-control custom-switch mx-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isStudyMode"
                    checked={state.isStudyMode}
                    onChange={(e) => setState(p => ({
                      ...p,
                      isStudyMode: e.target.checked
                    }))}
                  />
                  <label className="custom-control-label" htmlFor="isStudyMode">Is Study Mode?</label>
                </div>
              </div>

              <Button type="submit" className="btn primary-button mt-3 px-5">
                {loading ? <Spinner animation="grow" /> : "Submit"}
              </Button>
            </>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
