import { Container, Table } from "react-bootstrap";
import useSWR from "swr";
import { Loader } from "../components";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import {
	deleteSigma,
	getSigmas,
	updateSigmaStatus,
} from "../APIS";
import { useSelector } from "react-redux";
import { FcNext, FcPrevious } from "react-icons/fc";
import { checkAuthError } from "../utils";
import { State } from "../types";
import { Link } from "react-router-dom";

const LIMIT = 10;
export const Sigmas = () => {
	const [currentPage, setCurrentPage] = useState(1);

	const { data, mutate, error } = useSWR("/getSigmas", () =>
		getSigmas(currentPage, LIMIT)
	);
	const token = useSelector((state: State) => state.admin.token);


	const deleteHandler = async (item: any) => {
		const c = window.confirm(`Are you sure you want to delete this Sigmas?`);
		if (!c) return;
		try {
			await deleteSigma(item._id, token);
			mutate();
			toast.success("Deleted successfully");
		} catch (error: any) {
			checkAuthError(error);
			toast.error(error.message);
		}
	};
	const statusChangeHandler = async (event: any, id: string) => {
		const c = window.confirm(
		  `Are you sure to ${event.target.checked ? "Hide" : "Show"}?`
		);
		if (!c) return;
		try {
		  await updateSigmaStatus({ id, status: event.target.checked }, token);
		  mutate();
		} catch (error: any) {
		  checkAuthError(error);
		  toast.error(error.message);
		}
	  };

	if (error) {
		alert(error.message);
		return <></>;
	}
	if (!data) return <Loader />;

	return (
		<Container>
			<Table>
				<thead>
					<tr>
						<th>#</th>
						<th>File</th>
						<th>Title</th>
						<th>Status</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{data.data.data.map((item: any, index: number) => (
						<tr>
							<td>{index + 1}</td>
							<td>
								{
									item.isVideoSigma ? (
										<video width="200" height="150" controls>
											<source src={item.url} />
										</video>
									) : (
										<img
											width={50}
											height={50}
											style={{ borderRadius: "50%" }}
											src={
												item.url
													? item.url
													: "placeholder.png"
											}
											alt={item.title}
										/>
									)
								}

							</td>
							<td>{item.title}</td>
							<td>
								<div className="form-check form-switch">
									<input
										className="form-check-input"
										type="checkbox"
										id="sigmaStatus"
										onChange={(e) => statusChangeHandler(e, item._id)}
										checked={item.status}
									/>
									<label className="form-check-label" htmlFor="sigmaStatus">
										Show/Hide
									</label>
								</div>
							</td>
							<td>
								<Link
									className="btn btn-success"
									to={"/sigmas/sigma"}
									state={item}
								>
									Details
								</Link>
							</td>
							<td>
								<Button
									className="btn btn-danger"
									onClick={() => deleteHandler(item)}
								>
									Delete
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<Container
				className="pagination-container"
				style={{
					display: "flex",
					alignItems: "center",
					gap: "10px",
					maxWidth: "150px",
					margin: 0,
				}}
			>
				<Button
					className="btn primary-button"
					disabled={currentPage === 1}
					style={{ display: "flex", alignItems: "center", gap: "5px" }}
					onClick={() => {
						setCurrentPage((p) => p - 1);
						setTimeout(() => {
							mutate();
						}, 100);
					}}
				>
					<FcPrevious />
					<>Previous</>
				</Button>
				<Container
					style={{ display: "flex", alignItems: "center", gap: "5px" }}
				>
					<span> {currentPage}</span> <span>/</span>
					<span> {data.data.totalPages}</span>
				</Container>
				<Button
					className="btn primary-button px-2"
					onClick={() => {
						setCurrentPage((p) => p + 1);
						setTimeout(() => {
							mutate();
						}, 100);
					}}
					disabled={data.data.totalPages <= currentPage}
					style={{ display: "flex", alignItems: "center", gap: "5px" }}
				>
					<>Next</>
					<FcNext />
				</Button>
			</Container>
		</Container>
	);
};
