import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { PiUsersFourFill } from "react-icons/pi";
import { FiLogOut } from "react-icons/fi";
import { BiCategoryAlt } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { authHandler } from "../../store/reducers";
import { State } from "../../types";
import {  GiNewspaper } from "react-icons/gi";
import { GrChannel } from "react-icons/gr";
import { FaQuestion } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import "./style.css";

export const Sidebar = ({ children }: { children: ReactNode }) => {
  const [toggle, setToggle] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const dispatch = useDispatch();
  const token = useSelector((state: State) => state.admin.token);

  const logoutHandler = () => {
    const c = window.confirm("Are you sure to logout?");
    if (c) {
      sessionStorage.removeItem("material-admin");
      dispatch(authHandler(false));
    }
  };

  useEffect(() => {
    if (!window.location.pathname.includes(activeTab)) {
      setActiveTab(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, window.location.pathname]);



  return (
    <div className={`sidebar-container ${toggle && "toggled"}`}>
      <aside className="sidebar-wrapper">
        <div className="sidebar-brand">
          <h4 className="mt-4">News Sigma</h4>
        </div>
        <ul className="sidebar-nav">
          <li
            className={window.location.pathname.includes("dashboard") ||
              activeTab.includes("dashboard") ? "active" : ""}
            onClick={() => setActiveTab("dashboard")}
          >
            <Link to="/dashboard">
              <AiFillDashboard />
              <span>Dashboard</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("categories") ? "active" : ""}
            onClick={() => setActiveTab("categories")}
          >
            <Link to="/categories">
              <BiCategoryAlt />
              <span>Categories</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("channels") ? "active" : ""}
            onClick={() => setActiveTab("channels")}
          >
            <Link to="/channels">
              <GrChannel />
              <span>Channels</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("news") ? "active" : ""}
            onClick={() => setActiveTab("news")}
          >
            <Link to="/news">
              <GiNewspaper />
              <span>News</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("sigmas") ? "active" : ""}
            onClick={() => setActiveTab("sigmas")}
          >
            <Link to="/sigmas">
            <MdOutlineEmojiEmotions />
              <span>Sigmas</span>
            </Link>
          </li>
          <li
            className={window.location.pathname.includes("users") ||
              activeTab.includes("users") ? "active" : ""}
            onClick={() => setActiveTab("users")}
          >
            <Link to="/users">
              <PiUsersFourFill />
              <span>Users</span>
            </Link>
          </li>
          <li
            className={window.location.pathname.includes("faqs") ||
              activeTab.includes("faqs") ? "active" : ""}
            onClick={() => setActiveTab("faqs")}
          >
            <Link to="/faqs">
              <FaQuestion />
              <span>Faqs</span>
            </Link>
          </li>
          <li
            className={window.location.pathname.includes("youtubelinks") ||
              activeTab.includes("youtubelinks") ? "active" : ""}
            onClick={() => setActiveTab("youtubelinks")}
          >
            <Link to="/youtubelinks">
              <FaYoutube />
              <span>Youtube Links</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("logout") ? "active" : ""}
            onClick={logoutHandler}
          >
            <a href="#logout">
              <FiLogOut />
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </aside>
      {token && (
        <div className="navbar-wrapper">
          <nav className="navbar navbar-inverse">
            <div className="container-fluid">
              <div className="d-flex justify-content-between" style={{ width: "100%", marginRight: "20px" }}>
                <div className="navbar-header pointer">
                  <span onClick={() => setToggle((p) => !p)}>
                    <FaBars />
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )}

      <section className="content-wrapper">{children}</section>
    </div>
  );
};
