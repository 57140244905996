import { Container, Table } from "react-bootstrap";
import useSWR from "swr";
import { Loader } from "../components";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { State } from "../types";
import { FcNext, FcPrevious } from "react-icons/fc";
import { checkAuthError } from "../utils";
import { deleteUser, getUsers, updateUserStatus } from "../APIS";

const LIMIT = 10;
export const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, mutate, error } = useSWR("/getUsers", () =>
    getUsers(currentPage, LIMIT)
  );
  const token = useSelector((state: State) => state.admin.token);


  const statusChangeHandler = async (event: any, id: string) => {
    const c = window.confirm(
      `Are you sure to ${event.target.checked ? "Enabled" : "Disabled"}?`
    );
    if (!c) return;
    try {
      await updateUserStatus({ id, status: event.target.checked }, token);
      mutate();
    } catch (error: any) {
      checkAuthError(error);
      toast.error(error.message);
    }
  };

  const deleteHandler = async (id: any) => {
    const c = window.confirm(`Are you sure you want to delete this user?`);
    if (!c) return;
    try {
      await deleteUser(id, token);
      mutate();
      toast.success("Deleted successfully");
    } catch (error: any) {
      checkAuthError(error);
      toast.error(error.message);
    }
  };

  if (error) {
    alert(error.message);
    return <></>;
  }
  if (!data) return <Loader />;
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data.map((item: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <img
                  width={50}
                  height={50}
                  style={{ borderRadius: "50%" }}
                  src={
                    item.image && item.image.length
                      ? item.image
                      : "placeholder.png"
                  }
                  alt={item.fullName}
                />
              </td>
              <td>{item.fullName}</td>
              <td>{item.email}</td>
              <td>{item.contactNumber}</td>
              <td>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="customerStatus"
                    onChange={(e) => statusChangeHandler(e, item._id)}
                    checked={item.status}
                  />
                  <label className="form-check-label" htmlFor="customerStatus">
                    Disabled/Enabled
                  </label>
                </div>
              </td>
              <td>
                <Button
                  className="btn btn-danger"
                  onClick={() => deleteHandler(item._id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Container
        className="pagination-container"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          maxWidth: "150px",
          margin: 0,
        }}
      >
        <Button
          className="btn primary-button"
          disabled={currentPage === 1}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
          onClick={() => {
            setCurrentPage((p) => p - 1);
            setTimeout(() => {
              mutate();
            }, 100);
          }}
        >
          <FcPrevious />

          <>Previous</>
        </Button>
        <Container
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <span> {currentPage}</span> <span>/</span>
          <span> {data.data.totalPages}</span>
        </Container>
        <Button
          className="btn primary-button px-2"
          onClick={() => {
            setCurrentPage((p) => p + 1);
            setTimeout(() => {
              mutate();
            }, 100);
          }}
          disabled={data.data.totalPages <= currentPage}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <>Next</>
          <FcNext />
        </Button>
      </Container>
    </Container>
  );
};
